<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        sm="6"
      >
      <v-btn
        v-if="!asset.asset.id"
        color="primary"
        depressed
        :loading="loading"
        @click="chooseAssetDialog = true"
      >
        Choose Asset
      </v-btn>
      <div>
        <v-simple-table v-if="asset.asset.id">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Asset Number</th>
                <th class="text-left">Frame Number</th>
                <th class="text-left">Manafacturer / Model</th>
                <th class="text-left">Colour</th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ asset.asset.number }}</td>
                <td>{{ asset.asset.frame_number }}</td>
                <td>{{ asset.asset.manafacturer }} - {{ asset.asset.model }}</td>
                <td>{{ asset.asset.colour }}</td>
                <td>
                  <v-btn
                    :loading="loading"
                    color="error"
                    outlined
                    depressed
                    x-small
                    @click="$emit('asset-removed', asset.asset)"
                  >
                    Remove
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-textarea
          v-model="asset.customer_notes"
          label="Notes"
          outlined
          dense
          hint="Any additional notes that will help our mechanics, or tell us about any issues you want us to look at."
          persistent-hint
        />
      </v-col>
    </v-row>
    <ChooseAssetDialog
      @asset-selected="handleAssetSelected"
      :form="form"
      :dialog.sync="chooseAssetDialog"
    />

    <v-divider
      class="mb-5 mt-5"
    />
  </div>
</template>
<script>
import HasFormProp from '@/mixins/HasFormProp'
import ChooseAssetDialog from '@/components/assets/ChooseAssetDialog'

export default {
  name: 'ChooseAsset',

  props: {
    asset: {
      type: Object,
      required: true
    }
  },

  mixins: [ HasFormProp ],

  components: {
    ChooseAssetDialog
  },

  data () {
    return {
      chooseAssetDialog: false,
      loading: false
    }
  },

  methods: {
    handleAssetSelected (asset) {
      this.chooseAssetDialog = false
      this.$emit('asset-selected', asset)
    }
  }
}
</script>

<style lang="css" scoped>
</style>