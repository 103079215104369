<template>
  <div>
    <v-form
      ref="form"
      v-model="validForm"
    >
      <v-row>
        <v-col :cols="12" :md="4" class="pb-0">
          <v-text-field
            v-model="form.po_number"
            :disabled="hasCheckedForAvailability"
            :rules="[v => !!v || 'PO Number is required']"
            label="PO Number"
            outlined
            required
            dense
            autofocus
          />
        </v-col>
        <v-col :cols="12" :md="4" class="pb-0">
          <v-text-field
            v-model="form.number_of_bikes"
            :rules="[v => !!v || 'Number of bikes is required']"
            :min="2"
            :disabled="hasCheckedForAvailability"
            label="Number of Bikes"
            type="number"
            outlined
            required
            dense
          />
        </v-col>
        <v-col :cols="12" :md="4" class="pb-0">
          <v-text-field
            v-model="form.collection_postcode"
            :rules="[v => !!v || 'Collection / Service postcode is required']"
            :disabled="hasCheckedForAvailability"
            label="Collection / Service Postcode"
            outlined
            required
            dense
          />
        </v-col>
      </v-row>

      <FormErrors ref="formErrors" />

      <v-row>
        <v-col class="pt-0 mt-0">
          <v-btn
            v-if="hasCheckedForAvailability"
            color="primary"
            small
            outlined
            :loading="loading"
            @click="resetAvailabilityData"
          >
            Reset
          </v-btn>
          <v-btn
            v-if="!hasCheckedForAvailability"
            color="primary"
            depressed
            :loading="loading"
            @click="checkAvailability"
          >
            Check Availability
          </v-btn>
        </v-col>
      </v-row>

      <v-alert
        v-if="availabilityData.po_data && hasCheckedForAvailability"
        outlined
        class="mt-4 mb-0"
        border="top"
        type="success"
      >
        This PO number is associated with the following service plans: <strong>{{ availabilityData.po_data.name }}</strong>
      </v-alert>

      <v-alert
        v-if="!availabilityData.po_data && hasCheckedForAvailability"
        outlined
        class="mt-4 mb-0"
        border="top"
        type="warning"
      >
        This PO number is NOT associated with any service plans. Please ensure you have correctly entered the PO number. If it is correct, please continue.
      </v-alert>

      <v-alert
        v-if="hasCheckedForAvailability"
        text
        class="mt-4 mb-0"
        border="top"
        type="error"
      >
        <strong>IMPORTANT!</strong> Please ensure all assets are located at <u>{{ form.collection_postcode }}</u> on the date of collection. Any failed collections will incur a failed collection charge.
      </v-alert>

      <SnackBar success ref="successBar">
        We have found availability, please continue with the booking!
      </SnackBar>
      <SnackBar error ref="errorBar">
        There was an issue checking for availability. Please try again.
      </SnackBar>
    </v-form>
  </div>
</template>
<script type="text/javascript">
import SnackBar from '@/components/SnackBar'
import FormErrors from '@/components/FormErrors'
import HasFormProp from '@/mixins/HasFormProp'

export default {
  name: 'AvailabilityCheckFields',

  mixins: [ HasFormProp ],

  components: {
    SnackBar,
    FormErrors
  },

  watch: {
    'hasCheckedForAvailability': function () {
      this.$emit('update:hasCheckedForAvailability', this.hasCheckedForAvailability)
      this.$emit('update:availabilityData', this.availabilityData)
    }
  },

  computed: {
    hasCheckedForAvailability () {
      return this.availabilityData.collection_days.length > 0
    }
  },

  data () {
    return {
      loading: false,
      validForm: true,
      availabilityData: {
        collection_days: [],
        po_data: {}
      }
    }
  },

  methods: {
    resetAvailabilityData () {
        this.availabilityData = {
          collection_days: []
        }
    },

    checkAvailability () {
      if (this.$refs.form.validate()) {
        this.resetAvailabilityData()
        this.loading = true
        this.$refs.formErrors.clear()
        
        this.$api.post('availability', {
          po_number: this.form.po_number,
          postcode: this.form.collection_postcode,
          number_of_bikes: this.form.number_of_bikes,
          types: ['JOB_TYPE_FLEET']
        })
          .then(({ data: data }) => {
            this.availabilityData = data
            this.$refs.successBar.open()
          })
          .catch(error => {
            this.$refs.formErrors.setErrors(error)
            this.$refs.errorBar.open()
          })
          .finally(() => { this.loading = false })
      }
    }
  }
}
</script>
