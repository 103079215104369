<template>
  <v-alert
    v-if="error.message"
    class="mt-3 pt-5 pb-4"
    dense
    outlined
    type="error"
    border="top"
  >
    <strong>{{ error.message }}</strong>
    <ul v-if="error.errors" class="mt-2">
      <template
        v-for="(error, key) in error.errors"
      >
        <li
          v-for="(innerError, innerKey) in error"
          :key="key + innerKey"
        >
          {{ innerError }}
        </li>
      </template>
    </ul>
  </v-alert>
</template>
<script type="text/javascript">
export default {
  name: 'FormErrors',

  data () {
    return {
      error: {
        message: null,
        errors: {}
      }
    }
  },

  methods: {
    setErrors (error) {
      this.error = error
    },

    clear () {
      this.error = {
        message: null,
        errors: {}
      }
    }
  }
}
</script>
