<template>
  <v-container>
    <h1>New Booking</h1>
    <v-row>
      <v-col :cols="12">
        <v-card tile>
          <v-card-text>
            <AvailabilityCheckFields
              v-bind="{ form }"
              @update:hasCheckedForAvailability="(value) => { hasCheckedForAvailability = value }"
              @update:availabilityData="(value) => { availabilityData = value }"
            />

            <v-form
              v-if="hasCheckedForAvailability"
              ref="form"
              v-model="validForm"
            >
              <v-divider class="mb-5 mt-3" />

              <LocationFields
                v-bind="{ availabilityData }"
                :form.sync="form"
              />

              <v-divider class="mb-5 mt-5" />

              <DateFields
                v-bind="{ availabilityData }"
                :form.sync="form"
              />

              <v-divider class="mb-5 mt-5" />

              <ChooseAsset
                v-for="assetKey in numberOfBikesRange"
                :key="assetKey"
                :form="form"
                :asset="form.assets[assetKey]"
                @asset-selected="asset => { $set(form.assets, assetKey, { asset: asset }) }"
                @asset-removed="asset => { $set(form.assets, assetKey, { asset: {} }) }"
              />


              <v-alert
                text
                color="orange"
                outlined
                border="left"
                type="warning"
              >
                <div class="title">
                  Please Check Your Contact Details
                </div>
                <div>
                  Please check the contact details we have for you below. If they are empty, please fill them in.
                  <strong><i>These are the details we will use on the morning of collection / return.</i></strong>
                </div>
                <v-row>
                  <v-col :cols="12" :md="4" class="pb-0">
                    <v-text-field
                      v-model="form.phone"
                      :rules="[v => !!v || 'Phone number is required']"
                      label="Phone Number"
                      type="phone"
                      outlined
                      required
                      dense
                    />
                  </v-col>
                  <v-col :cols="12" :md="4" class="pb-0">
                    <v-text-field
                      v-model="form.mobile"
                      :rules="[v => !!v || 'Mobile number is required']"
                      label="Mobile Number"
                      type="phone"
                      outlined
                      required
                      dense
                    />
                  </v-col>
                  <v-col :cols="12" :md="4" class="pb-0">
                    <v-text-field
                      v-model="form.email"
                      :rules="[v => !!v || 'Email is required']"
                      label="Email"
                      type="email"
                      outlined
                      required
                      dense
                    />
                  </v-col>
                </v-row>
              </v-alert>

              <FormErrors ref="formErrors" />

              <v-btn
                color="success"
                large
                depressed
                :loading="loading"
                @click="submitBooking"
              >
                Submit Booking
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <SnackBar success ref="successBar">
      Booking created successfully!
    </SnackBar>
    <SnackBar error ref="errorBar">
      There was an issue creating the booking.
    </SnackBar>
  </v-container>
</template>
<script type="text/javascript">
import HasForm from '@/mixins/HasForm'
import SnackBar from '@/components/SnackBar'
import FormErrors from '@/components/FormErrors'
import AvailabilityCheckFields from '@/components/bookings/AvailabilityCheckFields'
import LocationFields from '@/components/bookings/LocationFields'
import DateFields from '@/components/bookings/DateFields'
import range from 'lodash.range'
import ChooseAsset from '@/components/bookings/ChooseAsset'

export default {
  name: 'NewBooking',

  mixins: [ HasForm ],

  components: {
    SnackBar,
    FormErrors,
    AvailabilityCheckFields,
    LocationFields,
    DateFields,
    ChooseAsset
  },

  data () {
    return {
      assets: [],
      availabilityData: {},
      form: {
        assets: [],
        number_of_bikes: 2,
        alternate_return_postcode: false
      },
      loading: false,
      hasCheckedForAvailability: false
    }
  },

  mounted () {
    this.form.phone = this.$auth.user.phone
    this.form.mobile = this.$auth.user.mobile
    this.form.email = this.$auth.user.email
  },

  computed: {
    numberOfBikesRange () {
      return range(0, this.form.number_of_bikes)
    },

    bookingToApi () {
      return {
        po_number: this.form.po_number,
        customer_id: this.$auth.user.id,
        customer_first_name: this.$auth.user.first_name,
        customer_last_name: this.$auth.user.last_name,
        customer_email: this.form.email,
        customer_mobile: this.form.mobile,
        customer_phone: this.form.phone,
        collection_postcode: this.form.collection_postcode,
        collection_address: this.form.collection_address,
        collection_date: this.form.collection_date,
        return_to_different_address: this.form.alternate_return_postcode,
        return_postcode: this.form.return_postcode,
        return_address: this.form.return_address,
        return_date: this.form.return_date,
        marketing_email_consent_at: null,
        marketing_email_consent_text: null,
        channel: 'CHANNEL_API_B2B',
        // campaign_type: this.,
        // campaign: this.,
        bikes: this.assetsToBikesApi,
      }
    },

    assetsToBikesApi () {
      var bikes = []
      this.form.assets.forEach(asset => {
        bikes.push({
          service_sku: 'regular',
          unknown_details: false,
          asset_id: asset.asset.id,
          manafacturer: asset.asset.manafacturer,
          type: asset.asset.type || 'TYPE_OTHER',
          model: asset.asset.model || asset.asset.frame_number,
          customer_notes: asset.customer_notes,
          colour: asset.asset.colour,
          additional_authorised_spend: 0,
          job_type: 'JOB_TYPE_FLEET'
        })
      })
      return bikes
    }
  },

  watch: {
    'hasCheckedForAvailability': function (checked) {
      if (checked) {
        this.$set(this.form, 'assets', [])
        this.numberOfBikesRange.forEach(() => {
          this.form.assets.push({
            asset: {}
          })
        })
        this.getAssets()
      }
    }
  },

  methods: {
    submitBooking () {
      this.$refs.formErrors.clear()
      
      if (this.$refs.form.validate()) {
        this.loading = true
        this.$api.post(`bookings`, this.bookingToApi)
          .then(() => {
            this.$refs.successBar.open()
            this.$router.push({ name: 'dashboard' })
          })
          .catch(error => {
            this.$refs.formErrors.setErrors(error)
            this.$refs.errorBar.open()
          })
          .finally(() => { this.loading = false })
      }
    },

    getAssets () {
      this.$api.get(`accounts/${this.$auth.account.id}/assets`, {
        params: {
          limit: 1000 * 100
        }
      })
        .then(({ data: assets }) => {
          this.assets = assets
        })
    }
  }
}
</script>
