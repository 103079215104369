<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="1600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Choose Asset</span>
        </v-card-title>
        <v-card-text>
          <DataTable
            ref="dataTable"
            :show-select="false"
            :cols="cols"
            :request-params="{ with: ['servicePlans', 'assetable', 'lastService'], po_number: form.po_number }"
            :path="`accounts/${$auth.account.id}/assets`"
            default-order-by="created_at"
          >
            <template v-slot:item.assetable="{ item: asset }">
              <template v-user-can:LIST_ACCOUNT_CHILDREN>
                <v-chip
                  small
                >
                  {{ asset.assetable.name }}                  
                </v-chip>
              </template>
              <span v-user-cant:LIST_ACCOUNT_CHILDREN>-</span>
            </template>
            <template v-slot:item.last_service_date="{ item: asset }">
              {{ asset.last_service ? $momentDate(asset.last_service.created_at) : '-' }}
            </template>
            <template v-slot:item.service_plans="{ item: asset }">
              <template v-user-can:LIST_ACCOUNT_SERVICE_PLANS>
                <v-chip
                  v-for="plan in asset.service_plans"
                  :key="plan.pivot.id"
                  small
                >
                  {{ plan.name }}
                </v-chip>
              </template>
              <span v-user-cant:LIST_ACCOUNT_SERVICE_PLANS>-</span>
            </template>
            <template v-slot:item.actions="{ item: asset }">
              <v-btn
                color="primary"
                x-small
                depressed
                outlined
                @click="$emit('asset-selected', asset)"
              >
                Choose Asset
              </v-btn>
            </template>
          </DataTable>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="darken-1"
            text
            @click="closeDialog"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import HasDialog from '@/mixins/HasDialog'
import DataTable from '@/components/DataTable'
import HasFormProp from '@/mixins/HasFormProp'

export default {
  name: 'ChooseAssetDialog',

  mixins: [ HasDialog, HasFormProp ],

  components: {
    DataTable
  },

  data: () => ({
    cols: [
      { text: 'Asset Number', value: 'number' },
      { text: 'Account', value: 'assetable' },
      { text: 'Frame Number', value: 'frame_number' },
      { text: 'Manafacturer', value: 'manafacturer' },
      { text: 'Model', value: 'model' },
      { text: 'Service Plans', value: 'service_plans' },
      { text: 'Last Service Date', value: 'last_service_date' },
      { text: '', value: 'actions' },
    ]
  })
}
</script>
<style lang="css" scoped>
</style>