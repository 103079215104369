<template>
  <div>
    <v-row>
      <v-col :cols="12" :md="4" class="pb-0">
        <h2>Collection Address</h2>
        <p>Please choose the address we will be collecting from.</p>
        <v-select
          v-model="form.collection_address"
          :items="availabilityData.addresses"
          label="Collection Address"
          :rules="[v => !!v || 'Please choose a collection address']"
          required
          outlined
          dense
        />

        <v-btn
          v-if="!form.alternate_return_postcode"
          text
          small
          dense
          outlined
          color="primary"
          @click="form.alternate_return_postcode = true"
        >
          Return to a different address?
        </v-btn>
        <v-btn
          v-else
          text
          small
          dense
          outlined
          color="primary"
          @click="form.alternate_return_postcode = false;
          form.return_address = null;
          form.return_postcode = null;
          returnAddressData = {}"
        >
          Return to the collection address?
        </v-btn>

        <template v-if="form.alternate_return_postcode">
          <h2 class="mt-4">Return Address</h2>
          <p>If you would like us to return the {{ form.number_of_bikes === 1 ? 'bike' : 'bikes' }} to a different address, please provide that below.</p>
          <v-row>
            <v-col
              class="py-0"
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="form.return_postcode"              
                :disabled="returnAddressData.addresses ? true : false"
                label="Return Postcode"
                :rules="[v => !!v || 'Please choose a enter a return postcode']"
                required
                outlined
                dense />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0">
              <v-btn
                v-if="returnAddressData.addresses ? false : true"
                :loading="loading"
                color="primary"
                @click="checkAvailability">
                Check Return Availability
              </v-btn>
            </v-col>
          </v-row>

          <v-alert
            v-if="returnPostcodeError.errors.postcode.length > 0"
            outlined
            border="top"
            type="error"
          >
            {{ returnPostcodeError.errors.postcode[0] }}
          </v-alert>

          <v-row>
            <v-col class="py-0">
              <v-select
                v-if="returnAddressData.addresses"
                v-model="form.return_address"
                :items="returnAddressData.addresses"
                :rules="[v => !!v || 'Please choose a return address']"
                required
                label="Return Address"
                outlined
                dense
              />
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>
  </div>
</template>
<script type="text/javascript">
import HasFormProp from '@/mixins/HasFormProp'

export default {
  name: 'LocationFields',

  mixins: [ HasFormProp ],

  props: {
    availabilityData: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      loading: false,
      returnAddressData: {},
      returnPostcodeError: {
        errors: {
          postcode: []
        }
      }
    }
  },

  methods: {
    checkAvailability () {
      this.loading = true
      this.$api.post('availability', {
          postcode: this.form.return_postcode,
          number_of_bikes: this.form.number_of_bikes
        }
      )
        .then(({ data }) => {
          this.returnAddressData = {
            postcode_data: data.postcode_data,
            collection_days: data.collection_days,
            return_days: data.return_days,
            addresses: data.addresses
          }
          this.returnPostcodeError = {
            errors: {
              postcode: []
            }
          }
        })
        .catch(error => {
          this.returnAddressData = {}
          this.returnPostcodeError = error
        })
        .finally(() => { this.loading = false })
    }
  }
}
</script>
