export default {
  props: {
    dialog: {
      type: Boolean,
      required: true
    }
  },

  methods: {
    closeDialog () {
      if (this.$refs.form) {
        // this.$refs.form.reset()
        this.$refs.form.resetValidation()
      }
      if (this.$refs.formErrors) {
        this.$refs.formErrors.clear()
      }
      this.form = {}
      this.$emit('update:dialog', false)
    }
  }
}