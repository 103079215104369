<template>
  <div>
    <v-row>
      <v-col :cols="12" class="pb-0">
        <h2>Collection Date</h2>
        <p>On what day would you like us to collect your {{ form.number_of_bikes === 1 ? 'bike' : 'bikes' }}?</p>

        <v-radio-group
          v-model="form.collection_date"
          row
          dense
        >
          <v-radio
            v-for="(day, key) in firstWeekCollectionDays"
            :key="key"
            :label="day.date.format('ddd Do MMM')"
            :value="day.date.format('YYYY-MM-DD')"
            :disabled="!day.available"
          />
        </v-radio-group>

        <h2>Return Date</h2>
        <p>And on what day would you like us to return the {{ form.number_of_bikes === 1 ? 'bike' : 'bikes' }} to you?</p>
        <v-radio-group
          v-model="form.return_date"
          row
          dense
        >
          <v-radio
            v-for="(day, key) in firstWeekReturnDays"
            :key="key"
            :label="day.date.format('ddd Do MMM')"
            :value="day.date.format('YYYY-MM-DD')"
            :disabled="!day.available"
          />
        </v-radio-group>
      </v-col>
    </v-row>
  </div>
</template>
<script type="text/javascript">
import HasFormProp from '@/mixins/HasFormProp'
import moment from 'moment'
import filter from 'lodash.filter'

export default {
  name: 'DateFields',

  mixins: [ HasFormProp ],

  props: {
    availabilityData: {
      type: Object,
      required: true
    }
  },

  watch: {
    'form.collection_date': function () {
      var dates = filter(this.firstWeekReturnDays, day => {
        return day.available
      })
      if (dates[0]) {
        this.$emit('update:form', {
          ...this.form,
          ...{
            return_date: dates[0].date.format('YYYY-MM-DD')
          }
        })
      }
    }
  },

  mounted () {
    var availableCollectionDates = filter(this.availabilityData.collection_days, day => {
      return day.available === true
    })
    this.$emit('update:form', {
      ...this.form,
      ...{
        collection_date: moment(availableCollectionDates[0].date).format('YYYY-MM-DD')
      }
    })
    // this.updateBookingFormValue(moment(availableCollectionDates[0].date).format('YYYY-MM-DD'), 'collectionDate')
  },

  computed: {
    firstWeekCollectionDays () {
      var days = []
      this.availabilityData.collection_days.forEach(day => {
        if (day.available) {
          days.push({ ...day, ...{ date: moment(day.date) } })
        }
      })
      return days.slice(0, 10)
    },

    firstWeekReturnDays () {
      var days = []
      this.availabilityData.return_days.forEach((day) => {
        var momentDate = moment(day.date)
        if (moment(this.form.collection_date).add(2, 'day') < momentDate) {
          days.push({ ...day, ...{ date: momentDate } })
        }
      })
      return days.slice(0, 7)
    }
  }
}
</script>
